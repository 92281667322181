import http from "@/utils/request";
// 获取房屋信息
export function getHouseInfo(data) {
  return http({
    method: "post",
    url: "/fast/potential/shopPotentialHourse/page.nd",
    data
  });
}
// 新增房屋信息
export function saveHouseInfo(data) {
  return http({
    method: "post",
    url: "/shopPotentialUserDetail/save.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
// 修改
export function updateHouseInfo(data) {
  return http({
    method: "post",
    url: "/shopPotentialUserDetail/update.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
// 删除
export function deletHouse(data) {
  return http({
    method: "post",
    url: "/shopPotentialUserDetail/delete.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
// 修改人员信息
export function updateShopPotentialUser(data) {
  return http({
    method: "post",
    url: "/shopPotentialUser/updateShopPotentialUser.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
