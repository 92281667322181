import { publicPath } from "@/common/constant.js";
import saleChange from "./components/saleChnge/index.vue";
import detailedInformation from "./components/detailedInformation/index.vue";
import purchaseRecords from './components/purchaseRecords/index.vue'
import AfterSalesRecords from './components/AfterSalesRecords/index.vue'
export default {
  name: "index",
  components: {
    saleChange,
    detailedInformation,
    purchaseRecords,
    AfterSalesRecords
  },
  data() {
    return {
      // 面包屑数据
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/IntentionList",
          name: "/IntentionList",
          title: "用户运营列表"
        },
        {
          path: "/",
          name: "/",
          title: "用户运营详情"
        }

      ],
      isFixed: false, //左侧是否跟随
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      scrollWatch: true,//滚动flag
      spinning: false, //页面整体loading
      menuList: [
        {
          title: "销售机会",
          active: true
        },
        {
          title: "详细资料",
          active: false
        },
        {
          title: "购买记录",
          active: false
        },
        {
          title: "售后记录",
          active: false
        }

      ],
      activeType: "销售机会",
      userId:this.$route.query.id ,//意向用户详情的id
    };
  },
  watch: {
    userId:function(val) {
      this.userId = val
    }
  },

  destroyed() {
    this.scrollWatch = false;
  },
  mounted() {
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },


    navClickFun(index, isScroll) {
      for (const idx in this.menuList) {
        if (idx == index) {
          //活动状态
          let timer = setTimeout(() => {
            for (const idx in this.menuList) {
              this.menuList[idx].active = false;
            }
            this.menuList[index].active = true;
            clearTimeout(timer);
          }, 1);
          return;
        }
      }
    },


    //左侧点击交互 暂时不用
    navJumpFun(index) {
      for (const idx in this.menuList) {
        this.menuList[idx].active = false;
      }
      this.menuList[index].active = true;
      if (index == 0) {
        this.activeType = "销售机会";
      } else if (index == 1) {
        this.activeType = "详细资料";
      } else if (index == 2) {
        this.activeType = "购买记录";
      } else if (index == 3) {
        this.activeType = "售后记录";
      }
    }
  }
};
