import {
  BuyRecord,
  getUser,
  shopPotentialVisit
} from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
import fllowUprecord from "./../followUprecord";
import makeReturnvisitPlan from './../makeReturnvisitPlan'
import FillinReturnVisitResults from './../FillinReturnVisitResults'
import { sendMessageA } from "@/views/userOperations/IntentionList/api";
import sendMessage from "@/views/userOperations/IntentionList/components/sendMessage";
export default {
  components: {
    fllowUprecord,
    makeReturnvisitPlan,
    FillinReturnVisitResults,
    sendMessage
  },
  data() {
    return {
      ProList: [],
      userInfo:{},
      pageLoadFlag:false,
      userLableList:[],
      palnvisible:false,
      qugenjinvisible:false,
      hfId:'',
      visible:false,
      cherkMessageItem:{},
      messageType:'',
    };
  },
  props: {
    userId: {
      //用户id
      type: String,
      default: ""
    }
  },
  watch: {
    userId: function(val) {
      this.userId = val;
    }
  },


  mounted() {
    this.getUserInfo()
    this.getList()
  },
  methods: {
    gotoBuy(item,type) {
      this.$router.push({
        path: "/installationRepairReporting/workOrderSubmission",
        query: {
          item: JSON.stringify(item),
          type:type,
        }
      })
    },
    // 发短信
    Pushmessage(type,item){
      this.cherkMessageItem = item
      this.messageType = type
      this.visible = true
    },
    confirmOk(data){
      let dataA  = {
        list:[
          {
            "sendMessageDTO":
              {"sysCode":"S0008",//":"信天翁编码"
                "sendTo":[this.cherkMessageItem.phone],//":"手机号"
                "contentSms":data.messageInfo
              },//":"发送内容"
            "custInfoId":this.$store.state.user.userInfo.customer.id,//":"商家id"
            "userId":this.userId,//":"用户id"
            "memoId":""//":"模板id，如需使用模板则传这个值，模板内容会覆盖上面的发送内容"
          }
        ]
      }
      sendMessageA(dataA).then(res=>{
        if(res.data.code == 0) {
          this.$message.success('发送成功',3)
          this.visible = false
        } else {
          this.$message.warning(res.data.msg,3)
          this.visible = false
        }
      })
    },
    // 打开定计划弹窗
    openDJh(item){
      this.hfId = String(item.id)
      this.palnvisible = true
    },
    // 制定计划
    cancelReasonHandOk(){
      this.getList()
      this.getUserInfo()

    },
    openFillinfollowVisible(item){
      this.hfId = String(item.id)
      this.qugenjinvisible = true
    },
    // 填写跟进记录
    FillinfollowUpOk() {
      this.getList()
      this.getUserInfo()
    },
    // 获取信息
    getList() {
      this.pageLoadFlag = true
      let data = {
        userId: this.userId,
        pageSize: 1000,
        pageNo: 1
      };
      BuyRecord(data).then(res => {
        this.ProList = res.data.list;
        if (this.ProList.length > 0) {
          this.ProList.forEach(item => {
            // 每个查看详情赋值默认隐藏
            item.display = false;
            // item.expend = true
            let data = {
              pageSize:1000,
              shopPotentialBuyId:item.id
            }
            // 查询跟进记录
            shopPotentialVisit(data).then(res=>{
              item.recoredList =  res.data.list
            })
          });
        }
        this.pageLoadFlag = false

      });
    },
    // 获取头部信息
    getUserInfo(){
      let data = {
        userId: this.userId,
      };
      getUser(data).then(res => {
        if(res.data.code == 0){
          this.userInfo = res.data.data;
          this.userLableList = this.userInfo.userLableList
          this.pageLoadFlag = false
        } else {
          this.userLableList = []
          this.pageLoadFlag = false
        }

      });
    },
    // 鼠标移入 展示查看详情
    mousemove(item) {
      item.display = true;
      this.$forceUpdate();
    },
    // 鼠标移出隐藏查看详情
    leave(item) {
      item.display = false;
      this.$forceUpdate();
    },
    // 展开收起
    openOrclose(item) {
      item.expend = !item.expend;
      this.$forceUpdate();
    }
  }

};
