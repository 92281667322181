import addEditPerson from "./../addEditPerInfo";
import addEditHouseJD from "@/views/userOperations/IntentionDetail/components/addEditHouseJD";
import { getUser, saveSale, upDateSale } from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
import {
  deletHouse,
  getHouseInfo,
  saveHouseInfo, updateHouseInfo, updateShopPotentialUser
} from "@/views/userOperations/IntentionDetail/components/detailedInformation/api";
import { sendMessageA } from "@/views/userOperations/IntentionList/api";
import sendMessage from "@/views/userOperations/IntentionList/components/sendMessage";


export default {
  components: {
    addEditPerson,
    addEditHouseJD,
    sendMessage
  },
  data() {
    return {
      ProList: [],
      visibleDelect: false,
      visibleaddEdit: false,
      typeText: "",
      efitInfo: {},
      visibleQWJD: false,
      jdInfo: {},// 家电信息，传给组件
      userInfo: {},// 用户信息传给组件
      pageLoadFlag:false,
      cherkItem:{},
      message:'',
      userLableList:[],
      deleIndex:'',
      visible:false,
      cherkMessageItem:{},
      messageType:'',

    };
  },
  props: {
    userId: {
      //用户id
      type: String,
      default: ""
    }
  },
  watch: {
    userId: function(val) {
      this.userId = val;
    }
  },

  mounted() {
    this.getUserInfo();
    this.getHouseInfo();
  },
  methods: {
    // 发短信
    Pushmessage(type,item){
      this.cherkMessageItem = item
      this.messageType = type
      this.visible = true
    },
    confirmOk(data){
      let dataA  = {
        list:[
          {
            "sendMessageDTO":
              {"sysCode":"S0008",//":"信天翁编码"
                "sendTo":[this.cherkMessageItem.phone],//":"手机号"
                "contentSms":data.messageInfo
              },//":"发送内容"
            "custInfoId":this.$store.state.user.userInfo.customer.id,//":"商家id"
            "userId":this.userId,//":"用户id"
            "memoId":""//":"模板id，如需使用模板则传这个值，模板内容会覆盖上面的发送内容"
          }
        ]
      }
      sendMessageA(dataA).then(res=>{
        if(res.data.code == 0) {
          this.$message.success('发送成功',3)
          this.visible = false
        } else {
          this.$message.warning(res.data.msg,3)
          this.visible = false
        }
      })
    },
    // 顶部用户信息
    getUserInfo() {
      this.pageLoadFlag = true
      let data = {
        userId: this.userId
      };
      getUser(data).then(res => {
        if(res.data.code == 0){
          this.userInfo = res.data.data;
          if(this.userInfo.sourceName == '活动报名') {
            this.userInfo.wechat = 1
          }

          this.userLableList = this.userInfo.userLableList
          this.pageLoadFlag = false
        } else {
          this.userLableList = []
          this.pageLoadFlag = false
        }

      });
    },
    // 获取房屋信息
    getHouseInfo() {
      this.pageLoadFlag = true
      let data = {
        userId: this.userId,
        pageSize: 1000,
        pageNo: 1
      };
      getHouseInfo(data).then(res => {
        if(res.data.code == 0){
          this.ProList = res.data.list;
          if (this.ProList.length > 0) {
            this.ProList.forEach(item => {
              // 每个查看详情赋值默认隐藏
              item.display = false;
            });
          }
          this.pageLoadFlag = false
        } else {
          this.$message.warning(res.data.msg)
          this.pageLoadFlag = false
        }


      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 打开弹窗 给当前选中赋值
    delectItem(item) {
      if (item) {
        this.cherkItem = item;
      } else {
        this.cherkItem = null;
      }
      if(item.community) {
        this.message = `确认删除${item.community}全屋家电信息?`
      } else {
        this.message = `确认删除${ item.address}全屋家电信息?`
      }

      this.$forceUpdate()
      this.visibleDelect = true;

    },
    // 弹窗确定（删除当前信息操作）
    delectOk() {
      this.pageLoadFlag = true
      let data = {
        id:this.cherkItem.id
      }
      deletHouse(data).then(res=>{
        if(res.data.code == 0) {
          this.$message.success('删除成功')
          this.getHouseInfo()
        }else {
          this.$message.success('删除失败,'+res.data.msg,5)
        }
      })
      this.pageLoadFlag = false
    },
    // 新增编辑弹窗赋值
    openAddEdit( item) {
      this.efitInfo = item;
      this.visibleaddEdit = true;
    },
    // 全屋家电弹窗开
    openQUJDwindow(type, item,index) {
      if (type == "编辑") {
        this.jdInfo = item;
        this.deleIndex = index.toString()
      } else {
        this.jdInfo = {};
        this.deleIndex = ''
      }
      this.typeText = type;
      this.visibleQWJD = true;

    },
    // 人员信息哦编辑弹窗确定
    getReason(data) {
      let dataA  = {
        id:this.userId,
        ...data
      }
      updateShopPotentialUser(dataA).then(res=>{
        if(res.data.type == 'success') {
          this.$message.success(res.data.text)
          this.getUserInfo()
        }
        else {
          this.$message.warning(res.data.msg)
          this.getUserInfo()
        }
      })

    },
    // 全屋家电确定
    getReasonqwjd(data) {
      //新增
      if (this.typeText == "新增") {
        this.pageLoadFlag = true
        saveHouseInfo(data).then(res => {
          if (res.data.code == 0) {
            this.$message.success("新增成功");
            this.pageLoadFlag = false
            this.getHouseInfo();
          } else {
            this.$message.warning("新增失败，"+res.data.msg,3);
            this.pageLoadFlag = false
          }
        });
      }
      // 编辑走这条
      if (this.typeText == "编辑") {
        this.pageLoadFlag = true
        updateHouseInfo(data).then(res => {
          if (res.data.code == 0) {
            this.$message.success("编辑成功");
            this.pageLoadFlag = false
            this.getHouseInfo();
          } else {
            this.$message.warning("编辑失败，"+res.data.msg,3);
            this.pageLoadFlag = false
          }
        });
      }
    },
    // 鼠标移入 展示查看详情
    mousemove(item) {
      item.display = true;
      this.$forceUpdate();
    },
    // 鼠标移出隐藏查看详情
    leave(item) {
      item.display = false;
      this.$forceUpdate();
    },
    // 展开收起
    openOrclose(item) {
      item.expend = !item.expend;
      this.$forceUpdate();
      if (item.expend) {
        item.ppList.forEach(item1 => {
          item1.showMore = true;
        });
      } else {
        item.ppList.forEach((item1, index) => {
          if (index == 0 || index == 1) {
            item1.showMore = true;
          } else {
            item1.showMore = false;
          }
        });
      }
      this.$forceUpdate();
    }
  }

};
