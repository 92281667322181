import { AfterSales, getSaleListInfo, getUser } from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
import { sendMessageA } from "@/views/userOperations/IntentionList/api";
import sendMessage from "@/views/userOperations/IntentionList/components/sendMessage";

export default {
  components: {
    sendMessage
  },
  data() {
    return {
      ProList: [],
      userInfo:{},
      pageLoadFlag:false,
      userLableList:[],
      visible:false,
      cherkMessageItem:{},
      messageType:'',

    };
  },
  props: {
    userId: {
      //用户id
      type: String,
      default: ""
    }
  },
  watch: {
    userId: function(val) {
      this.userId = val;
    }
  },


  mounted() {
    this.getUserInfo()
    this.getList()
  },
  methods: {
    // 发短信
    Pushmessage(type,item){
      this.cherkMessageItem = item
      this.messageType = type
      this.visible = true
    },
    confirmOk(data){
      let dataA  = {
        list:[
          {
            "sendMessageDTO":
              {"sysCode":"S0008",//":"信天翁编码"
                "sendTo":[this.cherkMessageItem.phone],//":"手机号"
                "contentSms":data.messageInfo
              },//":"发送内容"
            "custInfoId":this.$store.state.user.userInfo.customer.id,//":"商家id"
            "userId":this.userId,//":"用户id"
            "memoId":""//":"模板id，如需使用模板则传这个值，模板内容会覆盖上面的发送内容"
          }
        ]
      }
      sendMessageA(dataA).then(res=>{
        if(res.data.code == 0) {
          this.$message.success('发送成功',3)
          this.visible = false
        } else {
          this.$message.warning(res.data.msg,3)
          this.visible = false
        }
      })
    },
    // 顶部用户信息
    getUserInfo() {
      this.pageLoadFlag = true
      let data = {
        userId: this.userId,
      };
      getUser(data).then(res => {
        if(res.data.code == 0){
          this.userInfo = res.data.data;
          this.userLableList = this.userInfo.userLableList
          this.pageLoadFlag = false
        } else {
          this.userLableList = []
          this.pageLoadFlag = false
        }
      });
    },

    getList() {
      this.pageLoadFlag = true
      let data = {
        userId: this.userId,
        pageSize: 1000,
        pageNo: 1
      };
      AfterSales(data).then(res => {
        this.ProList = res.data.list;
        if (this.ProList.length > 0) {
          this.ProList.forEach(item => {
            // 每个查看详情赋值默认隐藏
            item.display = false;
          });
        }
        this.pageLoadFlag = false
      });
    },

    // 鼠标移入 展示查看详情
    mousemove(item) {
      item.display = true;
      this.$forceUpdate();
    },
    // 鼠标移出隐藏查看详情
    leave(item) {
      item.display = false;
      this.$forceUpdate();
    },
    // 展开收起
    openOrclose(item) {
      item.expend = !item.expend;
      this.$forceUpdate();
      if (item.expend) {
        item.ppList.forEach(item1 => {
          item1.showMore = true;
        });
      } else {
        item.ppList.forEach((item1, index) => {
          if (index == 0 || index == 1) {
            item1.showMore = true;
          } else {
            item1.showMore = false;
          }
        });
      }
      this.$forceUpdate();
    }
  }

};
