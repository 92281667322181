<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelName"
    :width="842"
    :closable="false"
    centered
    :maskClosable="false"小区

  >
    <a-spin :spinning="pageLoadFlagqq">
      <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
      </div>
      <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>{{ typeText }}全屋家电信息
      </span>
      </div>
      <div class="cancelContent">
        <div class="topInfoBox">
          <div class="everyBoxee">
            <p class="lebel">小区名称：</p>
            <div class="topBox">
              <a-input class="chose-account" v-model="houseInfor.community" placeholder="请填写小区名称"></a-input>
            </div>
          </div>
          <div class="everyBoxee">
            <p class="lebel">户型：</p>
            <div class="topBox">
              <template>
                <a-auto-complete
                  class="chose-account"
                  placeholder="请输入户型"
                  show-search
                  v-model="houseInfor.hourseTypeName"
                  :value="houseInfor.hourseTypeName"
                  :data-source="houseTypeList"
                  :filter-option="false"
                  :defaultActiveFirstOption="true"
                  @search="handleChangeHouseType"
                  @select="search"
                >
                  <a-spin slot="notFoundContent" size="small" />

                  <template slot="dataSource">
                    <a-select-option
                      :value="item.name"
                      v-for="(item,index) in houseTypeList"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </template>
                </a-auto-complete>
              </template>
            </div>
          </div>
          <div class="everyBoxee">
            <p class="lebel"><span style="color:#D9161C;">*</span>用户所在地区：</p>
            <div class="topBox bosbox">
              <address-from width="418" height="32" @onAddressChange="onAddressChange" :addressDftVal="addressHx" />
<!--              <a-input placeholder="请输入街道号、楼牌号" class="userDsInputress" v-model="houseInfor.address"-->
<!--                       style="width: 418px"></a-input>-->
              <a-auto-complete
                class="userDsInputress"
                style="margin-top: 16px;width:418px;border: none"
                placeholder="请输入街道号、楼牌号"
                show-search
                v-model="houseInfor.address"
                :value="houseInfor.address"
                :data-source="companyList"
                :filter-option="false"
                :defaultActiveFirstOption="true"
                @search="getAdress"
                @select="searcha"

              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />

                <template slot="dataSource">
                  <a-select-option
                    :value="item.name"
                    v-for="(item,index) in companyList"
                    :key="index"
                  >
                    {{ item.name }}
                  </a-select-option>
                </template>
              </a-auto-complete>
            </div>
          </div>
        </div>
        <div class="bottomBox">
          <p class="lable">家电信息：</p>
          <div class="rightBox">
            <div class="aaaBox">
              <div class="editBox"
                   v-show="productList.length > 0"
              >
                <div class="smallBox"
                     v-for="(item,index) in productList"
                     :key="index"
                >
                  <div class="leftBlock">
                    <p :class="item.productAge  ? 'productName':'productNameTop'">{{ item.spartName }}</p>
                    <p class="year">{{ item.productAge }}<span  v-if="item.productAge">年</span></p>
                  </div>
                  <div class="imgBox">
                    <img v-if="item.spartName == '空调'" src="@/assets/IntentionList/空调蓝色.png" alt="">
                    <img v-if="item.spartName == '洗衣机'" src="@/assets/IntentionList/洗衣机蓝色.png" alt="">
                    <img v-if="item.spartName == '电视'" src="@/assets/IntentionList/电视蓝色.png" alt="">
                    <img v-if="item.spartName == '冷柜'" src="@/assets/IntentionList/冷柜蓝色.png" alt="">
                    <img v-if="item.spartName == '厨卫'" src="@/assets/IntentionList/厨卫蓝色.png" alt="">
                    <img v-if="item.spartName == '冰箱'" src="@/assets/IntentionList/冰箱蓝色.png" alt="">
                  </div>
                  <div class="close">
                    <a-popconfirm
                      placement="topRight"
                      title="确认删除该产品？"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="delectOldProItem(item,index)"
                    >
                      <i class="iconfont icon-guanbi-gouwuche iconfontgwc"></i>
                    </a-popconfirm>
                  </div>
                </div>

              </div>
              <div class="HiddenBox">
                <div class="proBox"
                     v-for="(item,index) in productListNew"
                     :key="index"
                >
                  <div class="proItemBox">
                    <!--               品类-->
                    <a-select
                      class="chose-account"
                      show-search
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      @change="handleChangespart(item)"
                      v-model="item.spartId"
                      placeholder="请选择品类*"
                    >
                      <a-select-option
                        :value="item.code"
                        v-for="item in spartList"
                        :key="item.code"
                      >{{ item.name }}
                      </a-select-option>
                    </a-select>
                    <!--              品牌-->
                    <a-input class="chose-account2" placeholder="请输入品牌" @change="changebrand(item)"
                             v-model="item.brand"></a-input>
                    <!--              产品-->
                    <!--                <a-input class="chose-account" placeholder="请输入产品信息" @change="changeproduct(item)"-->
                    <!--                         v-model="item.product"></a-input>-->
                    <a-auto-complete
                      class="chose-account"
                      placeholder="请输入产品信息"
                      show-search
                      v-model="item.product"
                      :value="item.product"
                      :data-source="item.proList"
                      :filter-option="false"
                      :defaultActiveFirstOption="true"
                      @search="getProduct(item,$event)"


                    >
                      <a-spin v-if="item.fetching" slot="notFoundContent" size="small" />
                      <template slot="dataSource">
                        <a-select-option
                          :value="item"
                          v-for="(item) in item.proList"
                          :key="item"
                        >
                          {{ (item).trim() }}
                        </a-select-option>
                      </template>
                    </a-auto-complete>
                    <!--  年限-->
                    <a-input class="chose-account3" placeholder="请输入年限（数字）" @change="changeproduct(item)"
                             v-model="item.productAge"></a-input>
                    <!--               备注-->
                    <a-input class="chose-account4" placeholder="请输入备注（不超过十个字）" @change="changeProName(item)"
                             v-model="item.remark"></a-input>
                  </div>
                  <div class="close">
                    <a-popconfirm
                      placement="topRight"
                      title="确认删除该产品？"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="delectProItem(item,index)"
                    >
                      <i class="iconfont icon-guanbi-gouwuche iconfontgwc"></i>
                    </a-popconfirm>
                  </div>
                </div>
              </div>
            </div>

            <div class="addPro" @click="addProItem">
              <img src="@/assets/baseInfo/录入添加@2x.png">新增家电信息
            </div>

          </div>

        </div>
      </div>
      <div class="cancelInfo">
      </div>
    </a-spin>
  </a-modal>
</template>
<script>
import { getDefevtiveProByMatkl, potentialSpart } from "@/views/userOperations/IntendedUserEntry/api";
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import {
  getHouseInfo,
  saveHouseInfo,
  updateHouseInfo
} from "@/views/userOperations/IntentionDetail/components/detailedInformation/api";
import { getAdressapi } from "@/views/settlement/api";
import { getUser } from "@/views/userOperations/IntentionDetail/components/saleChnge/api";

export default {
  name: "cancel-modal",
  data() {
    return {
      userAdress: "",
      shengCode: "",
      shiCode: "",
      quCode: "",
      xianCode: "",
      // 产品信息 新的增加的数据
      productListNew: [],
      // 意向品类
      spartList: [],
      hourseTypeId: "",// 户型
      productList: [],//编辑的时候需要回显的产品数据
      houseInfor: {
        community: "",// 小区名称
        address: "",// 详细地址
        hourseTypeName: ""// 户型id
      },
      houseTypeList: [],
      addressHx: [],// 回显数据
      ProList:[],
      pageLoadFlagqq:false,
      companyList: [],
      fetching: false,
    };
  },
  props: {
    userId: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    },
    typeText: {
      type: String,
      default: ""
    },
    // Info: {
    //   type: Object
    // },
    deleIndex:{
      type: String,
      default: ""
    }
  },
  watch: {

    deleIndex:function(val) {

    },
    typeText:function(val) {
      if(val == '编辑') {
        let data = {
          userId: this.userId,
          pageSIze: 1000,
          pageNo: 1
        };
        this.pageLoadFlagqq = true
        getHouseInfo(data).then(res => {
          if(res.data.code == 0){
            this.AllInfo = res.data.list;
            this.houseInfor = this.AllInfo[this.deleIndex]
            this.addressHx = [this.houseInfor.provinceCode, this.houseInfor.cityCode, this.houseInfor.districtCode, this.houseInfor.townCode];
            this.shengCode = this.houseInfor.cityCode;
            this.shiCode = this.houseInfor.provinceCode;
            this.quCode = this.houseInfor.districtCode;
            this.xianCode = this.houseInfor.townCode;
            if ( this.houseInfor.hourseProductDtoList.length > 0) {
              this.productList = this.houseInfor.hourseProductDtoList;
              this.productListNew = [];
            }
            this.pageLoadFlagqq = false
          } else {
            this.$message.warning(res.data.msg)
            this.pageLoadFlagqq = false
          }
        });
      } else{
        this.houseInfor.address = ''
        this.houseInfor.community = ''
        this.houseInfor.hourseTypeName = ''
        this.productList = []
        this.addressHx = [];
        this.shengCode ='';
        this.shiCode = '';
        this.quCode = '';
        this.xianCode = '';
        this.productListNew = [
          {
            id: "",
            spartId: undefined,
            product: "",
            brand: "",
            productAge: "",
            remark: "",
            proList: [],
            fetching: false
          }
        ]

      }

    },
    visible:function(val) {
     if(val) {
       if(this.typeText == '编辑') {
         let data = {
           userId: this.userId,
           pageSIze: 1000,
           pageNo: 1
         };
         this.pageLoadFlagqq = true
         getHouseInfo(data).then(res => {
           if(res.data.code == 0){
             this.AllInfo = res.data.list;
             this.houseInfor = this.AllInfo[this.deleIndex]
             this.addressHx = [this.houseInfor.provinceCode, this.houseInfor.cityCode, this.houseInfor.districtCode, this.houseInfor.townCode];
             this.shengCode = this.houseInfor.cityCode;
             this.shiCode = this.houseInfor.provinceCode;
             this.quCode = this.houseInfor.districtCode;
             this.xianCode = this.houseInfor.townCode;
             if ( this.houseInfor.hourseProductDtoList.length > 0) {
               this.productList = this.houseInfor.hourseProductDtoList;
               this.productListNew = [];
             }
             this.pageLoadFlagqq = false
           } else {
             this.$message.warning(res.data.msg)
             this.pageLoadFlagqq = false
           }
         });
       } else if(this.typeText== '新增') {
             // 顶部用户信息
             let data = {
               userId: this.userId
             };
             getUser(data).then(res => {
               if(res.data.code == 0){
                 this.addressHx = res.data.data.provinceCode?[res.data.data.provinceCode,res.data.data.cityCode,res.data.data.districtCode,res.data.data.townCode]:[]
                 this.shengCode = res.data.data.provinceCode?res.data.data.provinceCode:'';
                 this.shiCode = res.data.data.cityCode?res.data.data.cityCode:'';
                 this.quCode = res.data.data.districtCode?res.data.data.districtCode:'';
                 this.xianCode = res.data.data.townCode?res.data.data.townCode:'';
                 this.houseInfor.address = res.data.data.address
                 if(this.addressHx.length<=0) {
                   // 获取登录接口的数据
                   this.addressHx = this.$store.state.user.userInfo.customer.currentTradeCode?[
                     this.$store.state.user.userInfo.customer.currentTradeCode,
                     this.$store.state.user.userInfo.customer.currentCityCode,
                     this.$store.state.user.userInfo.customer.currentAreaCode,
                     this.$store.state.user.userInfo.customer.townCode
                   ]:[]
                   this.shengCode =  this.$store.state.user.userInfo.customer.currentTradeCode? this.$store.state.user.userInfo.customer.currentTradeCode:'';
                   this.shiCode =  this.$store.state.user.userInfo.customer.currentCityCode? this.$store.state.user.userInfo.customer.currentCityCode:'';
                   this.quCode =  this.$store.state.user.userInfo.customer.currentAreaCode? this.$store.state.user.userInfo.customer.currentAreaCode:'';
                   this.xianCode =  this.$store.state.user.userInfo.customer.townCode? this.$store.state.user.userInfo.customer.townCode:'';
                   this.houseInfor.address = ''
                 }
               } else {
                 // 获取登录接口的数据
                 this.addressHx = this.$store.state.user.userInfo.customer.currentTradeCode?[
                   this.$store.state.user.userInfo.customer.currentTradeCode,
                   this.$store.state.user.userInfo.customer.currentCityCode,
                   this.$store.state.user.userInfo.customer.currentAreaCode,
                   this.$store.state.user.userInfo.customer.townCode
                 ]:[]
                 this.shengCode =  this.$store.state.user.userInfo.customer.currentTradeCode? this.$store.state.user.userInfo.customer.currentTradeCode:'';
                 this.shiCode =  this.$store.state.user.userInfo.customer.currentCityCode? this.$store.state.user.userInfo.customer.currentCityCode:'';
                 this.quCode =  this.$store.state.user.userInfo.customer.currentAreaCode? this.$store.state.user.userInfo.customer.currentAreaCode:'';
                 this.xianCode =  this.$store.state.user.userInfo.customer.townCode? this.$store.state.user.userInfo.customer.townCode:'';
                 this.houseInfor.address = ''
               }
             });
       }
     }
    },

  },
  mounted() {
    this.getmaterialGrouplist();
    this.getBuyList();
  },
  methods: {
    //查询数据
    searcha() {

    },
    getAdress(value) {
      let id = null;
      if (this.quCode) {
        id = this.quCode;
      } else if (this.shiCode) {
        id = this.shiCode;
      } else if (this.shengCode) {
        id = this.shengCode;
      } else {
        id = 0;
      }

      this.houseInfor.address = value;
      let a = {
        searchstr: this.houseInfor.address,
        region: id
      };
      let b = {
        searchstr: this.houseInfor.address
      };
      let data = id !== 0 ? a : b;
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList = [];
        }

        this.fetching = false;
      });

    },
    // 获取产品
    getProduct(item, value) {
      let data = {
        term: value
      };
      item.fetching = true;
      getDefevtiveProByMatkl(data).then(res => {
        if (res.data.list.length > 0 && res.data.list !== null) {
          item.proList = [...new Set(res.data.list)]; //缓存列表数据，前端分页
        } else {
          item.proList = [];
        }
        item.fetching = false;
        this.$forceUpdate();
      });
    },
    //房屋类型
    getBuyList() {
      let data = {
        pid: 90800
      };
      focusonremarkplz(data).then(res => {
        this.houseTypeList = res.data.list;
      });
    },
    // 获取意向品类
    getmaterialGrouplist() {
      let data = {};
      potentialSpart(data).then(res => {
        if (res.data.code == 0) {
          this.spartList = res.data.list;
        } else {
          this.$message.warning(res.data.msg);
        }

      });

    },
    // 户型修改
    handleChangeHouseType(e) {


    },
    search(e) {

    },
    // 品牌
    changebrand() {

    },
    changeproduct() {

    },
    // 新增产品
    addProItem() {
      this.productListNew.push({
        id: "",
        spartId: undefined,
        product: "",
        brand: "",
        productAge: "",
        remark: "",
        proList: [],
        fetching: false
      });
    },
    // 旧的商品行数据删除
    delectOldProItem(item, index) {
      this.productList.splice(index, 1);
      this.$forceUpdate()
    },
    // 商品行数据删除
    delectProItem(item, index) {
      // 如果当前产品行数据 大于1 就减去  只剩一个 就清空
      if (this.productListNew.length > 1) {
        this.productListNew.splice(index, 1);
      } else {
        this.productListNew.forEach(item => {
          item.id = "";
          item.spartId = undefined;
          item.product = "";
          item.brand = "";
          item.productAge = "";
          item.remark = "";
          item.proList = [];
          item.fetching = false;
        });
      }
    },
    // 修改意向品类
    handleChangespart(item) {
    },
    // 修名称
    changeProName(item) {
    },
    // 修改预算
    handleChangeBudget(item) {
    },
    // 修改产品信息时间
    changeTime(date, dateString, item) {
      item.planBuyTimeStr = dateString;
    },
    onAddressChange(addressValArr) {
      if(addressValArr&&addressValArr.length == 2) {
        this.quCode = '';
      }
      // 长度如果等于3 就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.xianCode = '';
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.shengCode = item;
          }
          if (index == 1) {
            this.shiCode = item;
          }
          if (index == 2) {
            this.quCode = item;
          }
          if (index == 3) {
            this.xianCode = item;
          }
        });
      } else {
        this.shengCode = ''
        this.shiCode = ''
        this.quCode = ''
        this.xianCode = ''
      }

      // ['340000000000', '340600000000', '340603000000', '340603400000', __ob__: Observer]
      // ..
    },

    handleOk() {
      if (!this.shengCode || !this.shiCode || !this.quCode || !this.xianCode) {
        this.$message.warning("请选择用户所在地址");
        return;
      }
      if (!this.houseInfor.address) {
        this.$message.warning("请填写详细地址");
        return;
      }
      var proList2 = [];
      this.productListNew.forEach(item => {
        proList2.push({
          id: item.id ? item.id : "",
          spartId: item.spartId ? item.spartId : "",
          product: item.product ? item.product : "",
          brand: item.brand ? item.brand : "",
          productAge: item.productAge ? item.productAge : "",
          remark: item.remark ? item.remark : ""
        });
      });
      for (let i = 0; i < proList2.length; i++) {
        if (!proList2[i].spartId) {
          this.$message.warning(`第${i + 1}行品类不能为空,请选择`);
          return;
        }
      }
      if (this.typeText == "新增") {
        let data = {
          community: this.houseInfor.community,
          townCode: this.xianCode,
          address: this.houseInfor.address,
          userId: this.userId,
          hourseTypeName: this.houseInfor.hourseTypeName,
          hourseProductDtoList: proList2
        };
        this.addressHx = [];
        this.$emit("update:visible", false);
        this.$emit("cancelReasonHandOk", data);
        this.productListNew = [
          {
            spartId: undefined,
            product: "",
            brand: "",
            productAge: "",
            remark: "",
            proList: [],
            fetching: false
          }
        ];
        this.houseInfor.community = "";
        this.houseInfor.address = "";
        this.houseInfor.hourseTypeName = "";
        this.productList = []
        this.addressHx = [];
        this.shengCode = '';
        this.shiCode ='';
        this.quCode ='';
        this.xianCode ='';
      } else {
        let oldList = [];
        this.productList.forEach(item => {
          oldList.push({
            id: item.id,
            spartId: item.spartId ? item.spartId : "",
            product: item.product ? item.product : "",
            brand: item.brand ? item.brand : "",
            productAge: item.productAge ? item.productAge : "",
            remark: item.remark ? item.remark : ""
          });
        });
        let proDuctLists = proList2.concat(oldList);
        let data = {
          community: this.houseInfor.community,
          id: this.houseInfor.id,
          townCode: this.xianCode,
          address: this.houseInfor.address,
          userId: this.userId,
          hourseTypeName: this.houseInfor.hourseTypeName,
          hourseProductDtoList: proDuctLists
        };

        this.$emit("update:visible", false);
        this.$emit("cancelReasonHandOk", data);
        this.productListNew = [
          {
            spartId: undefined,
            product: "",
            brand: "",
            productAge: "",
            remark: "",
            proList: [],
            fetching: false
          }
        ];
        this.houseInfor.community = "";
        this.houseInfor.address = "";
        this.houseInfor.hourseTypeName = "";

      }


    },
    cancle() {
      this.$emit("update:visible", false);
      if (this.typeText == "新增") {
        this.productListNew = [
          {
            spartId: undefined,
            product: "",
            brand: "",
            productAge: "",
            remark: "",
            proList: [],
            fetching: false
          }
        ];
      } else {
        debugger
        this.productListNew = [];
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 0 0px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;

        .topInfoBox {
          .everyBoxee {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;

            .lebel {
              display: block;
              width: 107px;
              font-size: 14px;
              font-weight: 400;
              color: #262626;
              text-align: right;
              margin-right: 8px;
            }

            .topBox {
              .chose-account {
                width: 300px;
                margin-bottom: 24px;
              }
            }

            .bosbox {
              display: flex;
              flex-direction: column;

              .userDsInputress {
                margin: 24px 0 24px 0
              }
            }


          }
        }

        .bottomBox {
          display: flex;

          .lable {
            width: 107px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-top: 18px;
            text-align: right;

            span {
              color: #D9161C;
            }
          }

          .rightBox {
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .aaaBox {
              display: flex;
              flex-direction: column;

              .editBox {
                width: 659px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .smallBox {
                  width: 146px;
                  height: 72px;
                  background: #ECF8FF;
                  border-radius: 6px;
                  margin-right: 14px;
                  margin-bottom: 16px;
                  position: relative;
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  .leftBlock {
                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;
                    display: flex;
                    height:18px;
                    justify-content: center;

                    p {
                      min-width: 40px;
                      text-align: left;
                      margin-top: 0;
                      font-size: 12px;
                      line-height: 18px;
                      height: 18px;
                    }

                    .productName {
                      color: #262626;
                      font-weight: 500;
                      font-size: 14px;
                    }
                    .productNameTop {
                      margin-top: 6px;
                      color: #262626;
                      font-weight: 500;
                      font-size: 14px;
                    }
                    .year {
                      font-weight: 400;
                      color: #777777;
                      margin-top: 6px;
                    }

                  }

                  .imgBox {
                    position: absolute;
                    right: 16px;
                    top: 24px;

                    img {
                      width: 24px;
                      height: 24px;
                    }
                  }

                  .close {
                    position: absolute;
                    top: -8px;
                    right: -8px;

                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    color: #fff;
                    cursor: pointer;
                    background: #CCCCCC;

                    .iconfontgwc {
                      font-size: 20px;
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-direction: column;
                    }

                    .iconfontgwc:hover {
                      background-image: url('./../../../../assets/cart/close.png');
                      color: #fff;
                      background-size: 100%;
                    }

                  }
                }
              }
            }

            .HiddenBox {
              max-height: 300px;
              overflow: auto;

              .proBox {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;


                .proItemBox {
                  width: 624px;
                  height: 112px;
                  background: #F2F3F5;
                  border-radius: 6px;
                  margin-bottom: 16px;

                  .chose-account {
                    width: 180px;
                    height: 32px;
                    margin: 16px 16px 0px 16px;
                  }

                  .chose-account2 {
                    width: 200px;
                  }

                  .chose-account3 {
                    width: 180px;
                    margin: 16px 16px 0px 16px;
                  }

                  .chose-account4 {
                    width: 396px;
                    margin: 16px 0px 0px 0px;
                  }
                }

                .close {
                  margin-left: 16px;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  color: #AAAAAA;
                  cursor: pointer;

                  .iconfontgwc {
                    margin-top: -10px;
                    font-size: 20px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  }

                  .iconfontgwc:hover {
                    margin-top: -10px;
                    // background-color: #00AAA6;
                    // margin-top: -4px;
                    background-image: url('./../../../../assets/cart/close.png');
                    color: #fff;
                    background-size: 100%;

                  }

                }
              }
            }

            .addPro {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 418px;
              height: 32px;
              border-radius: 4px;
              border: 1px dashed #EEEEEE;
              cursor: pointer;

              img {
                width: 8px;
                height: 8px;
                margin-right: 4px;
              }

              font-size: 14px;
              font-weight: 400;
              color: #1890FF;
              line-height: 20px;
            }

            .addPro:hover {
              color: #1890FF;
              border: 1px dashed #1890FF;
              background: rgba(24, 144, 255, 0.08);
            }


          }

        }

        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }

      .cancelInfo {
        padding: 24px 0 50px 0px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 78px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          float: left;
          padding-left: 11px;
          margin-right: 9px;
        }

        .ant-input {
          width: 400px;
          height: 88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: -31px;
        right: -34px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 0px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


