import addEditModal from "./../addEditSaleInfo.vue";
import fllowUprecord from "./../followUprecord";
import makePlan from "./../makePlan.vue";
import FillinfollowUp from './../FillinfollowUp'
import sendMessage from "@/views/userOperations/IntentionList/components/sendMessage";

import {
  deletsale,
  getSaleListInfo,
  getUser,
  saveSale, saveShopPotentialVisit, shopPotentialVisit, upDateSale
} from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
import { sendMessageA } from "@/views/userOperations/IntentionList/api";

export default {
  components: {
    addEditModal,
    fllowUprecord,
    makePlan,
    FillinfollowUp,
    sendMessage
  },
  data() {
    return {
      ProList: [],
      visibleDelect: false,
      visibleaddEdit: false,
      typeText: "",
      efitInfo: {},
      efitInfo1:{},
      userInfo: {},
      userLableList:[],
      pageLoadFlag: false,
      cherkItem: {},
      //   定计划的弹窗
      palnvisible:false,
      //   去跟进的弹窗
      qugenjinvisible:false,
      outId:'',
      status:undefined,
      visible:false,
      cherkMessageItem:{},
      messageType:'',

    };
  },
  props: {
    userId: {
      //用户id
      type: String,
      default: ""
    }
  },
  watch: {
    userId: function(val) {
      this.userId = val;
    }
  },


  mounted() {
    this.getUserInfo();
    this.getSaleInfo();
  },
  methods: {
    // 发短信
    Pushmessage(type,item){
      this.cherkMessageItem = item
      this.messageType = type
      this.visible = true
    },
    confirmOk(data){
      let dataA  = {
        list:[
          {
            "sendMessageDTO":
              {"sysCode":"S0008",//":"信天翁编码"
                "sendTo":[this.cherkMessageItem.phone],//":"手机号"
                "contentSms":data.messageInfo
              },//":"发送内容"
            "custInfoId":this.$store.state.user.userInfo.customer.id,//":"商家id"
            "userId":this.userId,//":"用户id"
            "memoId":""//":"模板id，如需使用模板则传这个值，模板内容会覆盖上面的发送内容"
          }
        ]
      }
      sendMessageA(dataA).then(res=>{
        if(res.data.code == 0) {
          this.$message.success('发送成功',3)
          this.visible = false
        } else {
          this.$message.warning(res.data.msg,3)
          this.visible = false
        }
      })
    },
    // 打开定计划弹窗
    openDJh(item){
      this.outId = String(item.id)
      this.palnvisible = true
    },
    // 制定计划
    cancelReasonHandOk(data){
      this.getSaleInfo()
    },
    openFillinfollowVisible(item){
      this.efitInfo1 = item
      this.outId = String(item.id)
      this.status = undefined
      this.status = item.status?Number(item.status):100
      this.qugenjinvisible = true
    },
    // 填写跟进记录
    FillinfollowUpOk() {
      this.getSaleInfo()
      this.getUserInfo()
    },
    // 顶部用户信息
    getUserInfo() {
      this.pageLoadFlag = true;
      let data = {
        userId: this.userId
      };
      getUser(data).then(res => {
        if(res.data.code == 0){
          this.userInfo = res.data.data;
          this.userLableList = this.userInfo.userLableList
          this.pageLoadFlag = false;
        }else {
          this.userLableList = []
          this.pageLoadFlag = false;
        }

      });
    },
    // 获取销售信息列表
    getSaleInfo() {
      this.pageLoadFlag = true;
      let data = {
        userId: this.userId,
        pageSize: 1000,
        pageNo: 1
      };
      getSaleListInfo(data).then(res => {
        if (res.data.code == 0) {
          this.ProList = res.data.list;
          if (this.ProList.length > 0) {
            this.ProList.forEach(item => {
              // 每个查看详情赋值默认隐藏
              item.display = false;
              item.expend = true
              let data = {
                pageSize:1000,
                shopPotentialId:item.id
                // shopPotentialId:'5695456'
              }
              // 查询跟进记录
              shopPotentialVisit(data).then(res=>{
                if(res.data.code == 0) {
                  item.recoredList =  res.data.list
                  this.$forceUpdate()
                  this.pageLoadFlag = false;
                }else {
                  item.recoredList =  []
                  this.pageLoadFlag = false;
                  this.$message.warning(res.data.msg)
                }

              }).catch(error=>{
                console.log(error);
                this.pageLoadFlag = false;
                console.log(error);
              })
            });
          }
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    // 打开弹窗 给当前选中赋值
    delectItem(item) {
      if (item) {
        this.cherkItem = item;
      } else {
        this.cherkItem = null;
      }
      this.visibleDelect = true;
    },
    // 弹窗确定（删除当前信息操作）
    delectOk() {
      let data = {
        id: this.cherkItem.id
      };
      deletsale(data).then(res => {
        if (res.data.code == 0) {
          this.$message.success("删除成功");
          this.getSaleInfo();
        } else {
          this.$message.success("删除失败," + res.data.msg);
        }
      });
    },
    // 新增编辑弹窗赋值
    openAddEdit(num, item) {
      this.efitInfo = item;
      if (num == 1) {
        this.typeText = "编辑";
      } else {
        this.typeText = "新增";
      }
      this.visibleaddEdit = true;
    },
    // 新增编辑弹窗确定
    getReason(data) {
      //新增
      if (this.typeText == "新增") {
        this.pageLoadFlag = true;
        saveSale(data).then(res => {
          if (res.data.code == 0) {
            this.$message.success("新增成功");
            this.pageLoadFlag = false;
            this.getSaleInfo();
          } else {
            this.$message.warning("新增失败，" + res.data.msg, 3);
            this.pageLoadFlag = false;
          }
        });
      }
      // 编辑走这条
      if (this.typeText == "编辑") {
        this.pageLoadFlag = true;
        upDateSale(data).then(res => {
          if (res.data.code == 0) {
            this.$message.success("编辑成功");
            this.pageLoadFlag = false;
            this.getSaleInfo();
          } else {
            this.$message.warning("编辑失败，" + res.data.msg, 3);
            this.pageLoadFlag = false;
          }
        });
      }
    },
    // 鼠标移入 展示查看详情
    mousemove(item) {
      item.display = true;
      this.$forceUpdate();
    },
    // 鼠标移出隐藏查看详情
    leave(item) {
      item.display = false;
      this.$forceUpdate();
    },
    // 展开收起
    openOrclose(item) {
      item.expend = !item.expend;
      this.$forceUpdate();
      if (item.expend) {
        // item.ppList.forEach(item1 => {
        //   item1.showMore = true;
        // });
      } else {
        // item.ppList.forEach((item1, index) => {
        //   if (index == 0 || index == 1) {
        //     item1.showMore = true;
        //   } else {
        //     item1.showMore = false;
        //   }
        // });
      }
      this.$forceUpdate();
    }
  }
};
