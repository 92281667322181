<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNamekkk"
      :width="842"
      :closable="false"
      centered
      :maskClosable="false"

    >
      <a-spin :spinning="pageLoadFlag">
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>填写回访记录
        </span>
        </div>
        <div class="cancelContent">
          <div class="selectBox">
            <div class="everyBox">
              <p class="lable">回访方式：</p>
              <template>
                <a-auto-complete
                  style="width: 300px"
                  class="chose-account"
                  placeholder="请选择回访方式"
                  v-model="HFID"
                  :value="HFID"
                  :data-source="gjList"
                  :filter-option="false"
                  :defaultActiveFirstOption="true"
                >
                  <a-spin slot="notFoundContent" size="small" />

                  <template slot="dataSource">
                    <a-select-option
                      style="width: 300px!important"
                      :value="item.name"
                      v-for="(item,index) in gjList"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </template>
                </a-auto-complete>
              </template>
            </div>
            <div class="everyBox therem">
              <p class="lable">回访结果：</p>
              <a-textarea style="width: 400px"
                          placeholder="请输入本次回访结果(不超过800字)"
                          :auto-size="{ minRows: 3, maxRows: 30 }"
                          v-model="result"></a-textarea>
            </div>
            <div class="everyBox">
              <p class="lable">上传图片：</p>
              <div>
                <a-upload
                  list-type="picture-card"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  @change="handleChange"
                  :remove="handleRemove"
                  @preview="handlePreview"
                  style="display: flex;flex-direction: row-reverse"
                >
                  <div v-if="fileList.length<3">
                    <a-icon type="upload" />
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </div>
            <div class="everyBox" style="margin-top:16px">
              <p class="lable">计划下次回访时间：</p>
              <a-date-picker
                style="width: 300px"
                class="chose-account"
                @change="(data,dateString)=>changeTime(data,dateString)"
                placeholder="请输入下次回访时间"
                v-model="planTimeStr" />
            </div>
            <div class="everyBox">
              <p class="lable">计划回访方式：</p>
              <template>
                <a-auto-complete
                  style="width: 300px!important"
                  class="chose-account"
                  placeholder="请选择回访方式"
                  v-model="JHHFID"
                  :value="JHHFID"
                  :data-source="gjList"
                  :filter-option="false"
                  :defaultActiveFirstOption="true"
                >
                  <a-spin slot="notFoundContent" size="small" />

                  <template slot="dataSource">
                    <a-select-option
                      style="width: 300px!important"
                      :value="item.name"
                      v-for="(item,index) in gjList"
                      :key="index"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </template>
                </a-auto-complete>
              </template>
            </div>
            <div class="everyBox therem">
              <p class="lable">计划下次回访主题：</p>
              <a-textarea style="width: 400px"
                          placeholder="请输入本次回访主题(不超过800字)"
                          :auto-size="{ minRows: 3, maxRows: 30 }"
                          v-model="theme"></a-textarea>
            </div>

          </div>
        </div>
      </a-spin>
    </a-modal>

  </div>
</template>
<script>

import { publicPath } from "@/common/constant";
import axios from "axios";
import {
  findListLabel,
  getDefevtiveProByMatkl,
  potentialSpart,
  saveInfoLabel
} from "@/views/userOperations/IntendedUserEntry/api";
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { getQuestionTypes } from "@/views/questionnaire/components/api";
import { updateShopPotentialVisit } from "@/views/userOperations/IntentionDetail/components/saleChnge/api";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  components: {},
  data() {
    return {
      gjList:[],// 跟进方式
      pageLoadFlag: false,// 整个页面的loading
      previewVisible: false,
      HFID:undefined,
      JHHFID:undefined,
      planTimeStr:'',
      result:'',//跟进结果
      fileList:[],
      fileIds:[],// 附件id
      urlArr:[],// 附件地址
      previewImage: '',
      yxstatus:1,// 意向状态
      // 产品信息
      productList: [
        {
          id: "",
          spartId: undefined,
          product: "",
          budget: undefined,
          planBuyTimeStr: "",
          proList: [],
          fetching: false
        }
      ],
      spartList: [],   // 意向品类
      budgetList: [],// 购买预算
      addressHx: [],// 回显数据
      visibleTips: false,// 新建用户标签弹窗
      therrr:[],
      TipsIdTC:[],
      userTip:'',
      tipsArea:'',
      pageLoadFlagbq:false,
      userTipsList:[],
      TipsIds:[],//
      theme:'',// 主题
      palngjList:[],// 计划跟进方式

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: ""
    },
    outId: {
      type: String,
      default: ""
    },

  },

  watch: {
  },
  mounted() {
    this.getGjTypes()
  },
  methods: {
    getGjTypes() {
      let data = {
        pid: 17378751205
      }
      getQuestionTypes(data).then(res => {
        this.gjList = res.data.list;
        this.HFID = '电话'
        this.JHHFID = ''

      })
    },


    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file1", file);
      formData.append("fileType", "shopPotentialUser");
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      // 发送请求
      this.Canbutton = true
      const BASE_API = publicPath;
      axios.post(BASE_API + '/comm/method=uploadFile.nd', formData, config).then(res => {
        if (res.data.message == '上传成功') {
          this.fileIds.push({
            id: res.data.businessId
          })
          this.urlArr.push( res.data.url)
        } else {
          this.$message.warning('上传失败', res.data.message)
        }
        this.Canbutton = false
      })
      return false;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1)
      this.urlArr.splice(index, 1);
    },

    // 修改时间
    changeTime(date, dateString) {
      this.planTimeStr = dateString;
    },

    handleOk() {
      let data = {
        visitWayName:null,// 传空
        visitResult:null,// 传空
        id:this.outId,//回访外层id
        finish:	1,
        pictureId: this.urlArr.length>0? this.urlArr.join(','):null,// 图片路径字符
        finishVisitWayName:this.HFID?this.HFID.replace(/\s*/g,""):null,
        finishVisitResult:this.result?this.result:null,
        shopPotentialUserId	:this.userId,
        visitWayNameNew		:this.JHHFID?this.JHHFID.replace(/\s*/g,""):null,//方式id（字典值）(选填)（这仨参数必须填0个或者3个）（填三个视为新建了一次下次的任务）
        visitDateNew:this.planTimeStr?this.planTimeStr:null,//		计划日期(选填)
        visitResultNew:this.theme?this.theme:null,//		计划主题（400字以内）(选填)
        shopPotential : {		//意向商品信息
          id	:'',
          detailList:[]
        },
        visitType:1,
        userLables:[],
        "user": {
          "id": this.userId
        }
      };
      this.pageLoadFlag = true
      updateShopPotentialVisit(data).then(res=>{
        if(res.data.code == 0) {
          this.$message.success(res.data.msg,3)
          this.pageLoadFlag = false
          this.$emit("update:visible", false);
          this.$emit("cancelReasonHandOk", data);
          this.$forceUpdate()
          this.fileIds = []
          this.urlArr = []
          this.fileList = []
          this.HFID =  '电话'
          this.result = ''
          this.JHHFID =  ''
          this.planTimeStr = ''
          this.remark=''
          this.theme = ''


        } else {
          this.$message.warning(res.data.msg,3)
          this.pageLoadFlag = false
        }
      }).catch(error=>{
        this.showLoading = false
      })
      // this.$emit("update:visible", false);
      // this.$emit("cancelReasonHandOk", data);
      // this.$forceUpdate()

    },
    cancle() {
      this.fileIds = []
      this.urlArr = []
      this.fileList = []
      this.HFID =  '电话'
      this.result = ''
      this.JHHFID =  ''
      this.planTimeStr = ''
      this.remark=''
      this.theme = ''
      this.$emit("update:visible", false);
      this.$forceUpdate()

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNamekkk {
  .ant-input{
    border: 1px solid #eee!important;
    width: 300px;
  }
  .ant-select-auto-complete.ant-select .ant-select-selection--single {
    width: 300px;
  }
 .ant-modal-content {
    text-align: center;
    border-radius: 10px;
    .ant-modal-body {
      padding: 30px 0px 0 0px;
      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        max-height:600px;
        overflow:auto;
        .selectBox {
          //margin-top: 16px;
          display: flex;
          flex-direction: column;
          .everyBox {
            display: flex;
            align-items: center;
            margin-left: 78px;
            margin-top: 24px;
            .lable {
              width: 126px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }

            /deep/ .ant-select-selection {
              width: 300px!important;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
            }

            .aInput {
              width: 416px !important;
              height: 32px !important;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
              color: #262626;
            }
            /deep/.ant-select-auto-complete.ant-select .ant-input {
              border-width: 0px!important;
              width: 300px!important;
            }
            .ant-select-auto-complete.ant-select .ant-input {
              border-width: 0px!important;
              width: 300px!important;
            }
            .userInput {
              width: 200px;
              margin-right: 16px;
            }

            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                //border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .therem {
            display: flex;
            align-items: flex-start;
            /deep/ textarea.ant-input {
              border: 1px solid #EEEEEE;
            }
          }
          .bottomBox {
            display: flex;
            //max-height: 300px;
            //overflow: auto;
            align-items: flex-start;
            //flex-direction: row;



            p {
              width: 104px;
              font-size: 14px;
              font-weight: 400;
              color: #262626;
              margin-top: 18px;
              text-align: right;

              span {
                color: #D9161C;
              }
            }

            .rightBox {
              margin-left: 8px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .proBox {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                /deep/ .ant-select-selection {
                  width: 180px;
                  height: 32px;
                  background: #FFFFFF;
                  border-radius: 4px;
                  border: 1px solid #EEEEEE;
                }
                .proItemBox {
                  width: 468px;
                  height: 112px;
                  background: #F2F3F5;
                  border-radius: 6px;
                  margin-bottom: 16px;

                  .chose-account {
                    width: 180px;
                    height: 32px;
                    margin: 16px 16px 0px 16px;
                    /deep/ .ant-select-selection {
                      width: 240px;
                      height: 32px;
                      background: #FFFFFF;
                      border-radius: 4px;
                      border: 1px solid #EEEEEE;
                    }
                  }

                  .chose-account2 {
                    /deep/ .ant-select-selection {
                      width: 240px;
                      height: 32px;
                      background: #FFFFFF;
                      border-radius: 4px;
                      border: 1px solid #EEEEEE;
                    }
                    width: 240px;
                  }
                  .ant-input {
                    width: 240px;
                    height: 32px;
                    overflow: auto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                  }
                }

                .close {
                  margin-left: 16px;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  color: #AAAAAA;
                  cursor: pointer;

                  .iconfontgwc {
                    margin-top: -10px;
                    font-size: 20px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                  }

                  .iconfontgwc:hover {
                    margin-top: -10px;
                    // background-color: #00AAA6;
                    // margin-top: -4px;
                    background-image: url('./../../../../assets/cart/close.png');
                    color: #fff;
                    background-size: 100%;

                  }

                }
              }

              .addPro {
                display: flex;
                justify-content: center;
                //align-items: center;
                width: 418px;
                height: 32px;
                border-radius: 4px;
                border: 1px dashed #EEEEEE;
                cursor: pointer;
                margin-left: -35px;


                img {
                  width: 8px;
                  height: 8px;
                  margin-right: 4px;
                  margin-top: 12px;
                }

                font-size: 14px;
                font-weight: 400;
                color: #1890FF;
                line-height: 32px;
              }

              .addPro:hover {
                color: #1890FF;
                border: 1px dashed #1890FF;
                background: rgba(24, 144, 255, 0.08);
              }


            }

          }
          .everyBox:first-child{
            margin-top: 40px;
          }

          .proBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start;

            .prooneBox {
              display: flex;

              .prouctBox {
                display: flex;
                flex-direction: column;

                .proItem {
                  display: flex;
                  margin-bottom: 16px;
                  align-items: center;

                  /deep/ .ant-select-selection {
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                  }

                }
              }
            }

            .addNEWbTN {
              cursor: pointer;
              width: 180px;
              height: 32px;
              border-radius: 3px;
              border: 1px dashed #EEEEEE;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #1890FF;
              margin-left: 332px;

              img {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }

          }

          .biaoqian {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .biaoqianBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start
            }

            .tips {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              margin-left: 109px;
              margin-top: -6px;
            }

          }
          .laiyuan {
            margin-top: 24px;
          }

          .adress {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              display: flex;
            }

            .userDsInputress {
              margin-top: 16px;
              margin-left: 106px;
              width: 500px;
            }
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }



      .modal_close {

        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


