<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelName"
      :width="842"
      :closable="false"
      centered
      :maskClosable="false"

    >
      <a-spin :spinning="pageLoadFlag">
        <!--    :ok-button-props="{ props: { disabled: submitBtn } }"-->
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>编辑基本信息
      </span>
        </div>
        <div class="cancelContent">
          <div class="selectBox">
            <div class="everyBox">
              <p class="lable"><span>*</span>用户信息：</p>
              <a-input placeholder="请输入手机号" class="userInput" v-model="formInfo.phone" disabled></a-input>
              <a-input placeholder="请输入姓名" class="userInput" v-model="formInfo.userName"></a-input>
              <a-radio-group name="radioGroup" class="radioGroup" @change="changSex" v-model="formInfo.gender">
                <a-radio :value="1">
                  男士
                </a-radio>
                <a-radio :value="2">
                  女士
                </a-radio>
              </a-radio-group>
            </div>
            <div class="everyBox">
              <p class="lable">是否添加微信：</p>
              <a-radio-group name="radioGroup" v-model="formInfo.wechat" @change="changWX">
                <a-radio :value="0">
                  未加
                </a-radio>
                <a-radio :value="1">
                  已加
                </a-radio>
              </a-radio-group>
            </div>
            <div class="everyBox biaoqian " v-show="!formInfo.sourceName">
              <div class="biaoqianBox">
                <p class="lable" style="margin-top:4px">用户来源：</p>
                <div class="userSorce"  v-if="formInfo.sourceId !== 5007694">
                  <div class="smallSorce"
                       v-for="(item,index)  in  userSorceList"
                       :key="index"
                       @click="changeSorceBtn(item,'0')"
                       :class="item.id == formInfo.sourceId?'smallSorceActice':'smallSorce'"
                  >
                    {{ item.source }}
                  </div>
                  <div  class="circleBox" @click="openVisebleLy">
                    <img src="@/assets/baseInfo/录入添加@2x.png">
                  </div>
                </div>
                <div class="userSorce"  v-else>
                  <div class="smallSorce"
                       v-for="(item,index)  in  userSorceList"
                       :key="index"
                       :class="item.id == formInfo.sourceId?'smallSorceActice':'smallSorce'"
                  >
                    {{ item.source }}
                  </div>
                  <div class="circleBox" @click="openVisebleLy">
                    <img src="@/assets/baseInfo/录入添加@2x.png">
                  </div>
                </div>

              </div>
            </div>
            <div class="everyBox biaoqian laiyuan">
              <div class="biaoqianBox">
                <p class="lable" style="margin-top:4px">用户标签：</p>
                <div class="userSorce">
                  <div class="smallSorce"
                       v-for="(item,index)  in  userTipsList"
                       :key="index"
                       @click="changeTipsBtn(item,'0')"
                       :class="item.checked?'smallSorceActice':'smallSorce'"
                  >
                    {{ item.label }}
                  </div>
                  <div class="circleBox" @click="openvisibleTips">
                    <img src="@/assets/baseInfo/录入添加@2x.png">
                  </div>
                </div>

              </div>
              <p class="tips">不可超过8个</p>
            </div>
            <div class="everyBox">
              <p class="lable">所属活动：</p>
              <a-input style="width: 300px" disabled v-model="formInfo.specialActivityName"/>
            </div>
            <div class="everyBox">
              <p class="lable">变更跟进人：</p>
              <a-select
                style="width: 300px"
                class="chose-account"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChangespart()"
                v-model="formInfo.followPeople"
              >
                <a-select-option
                  :value="item.code"
                  v-for="item in productList"
                  :key="item.code"
                >{{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="everyBox">
              <p class="lable">备注：</p>
              <a-textarea style="width: 500px" placeholder="请输入备注"  :auto-size="{ minRows: 3, maxRows: 30 }" v-model="formInfo.remark"></a-textarea>
            </div>
          </div>

        </div>

      </a-spin>
    </a-modal>
    <!--   用户来源-->
    <a-modal
      v-model="visibleLY"
      @ok="visibleLYOk"
      @cancel="clickLY"
      wrapClassName="cancelNameYHLY"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"

    >
      <div class="modal_close" @click="clickLY">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
      </div>
      <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>新建用户来源
      </span>
      </div>
      <a-spin :spinning="pageLoadFlagLy">
        <div class="cancelContent">
          <div class="btnGroup">
            <div
              v-show="formInfo.sourceId == 5007694"
              v-for="(item,index) in userSorceList"
              :key="index"
              :class="item.id == sourceIdTc ? 'clickBtnActive':'clickBtn'"

            >
              {{ item.source }}
            </div>
            <div
              v-show="formInfo.sourceId !== 5007694"
              v-for="(item,index) in userSorceList"
              :key="index"
              @click="changeSorceBtn(item,'1')"
              :class="item.id == sourceIdTc ? 'clickBtnActive':'clickBtn'"
            >
              {{ item.source }}
            </div>
          </div>
        </div>
        <div class="cancelInfo">
          <span class="cancleZi">自定义:</span>
          <a-input
            placeholder="请输入用户来源"
            :value="userself"
            v-model="userself"
          />
        </div>
      </a-spin>
    </a-modal>
    <!--用户标签  -->
    <a-modal
      v-model="visibleTips"
      @ok="TipsOk"
      @cancel="clickTIPs"
      wrapClassName="cancelNameTips"
      :width="648"
      :closable="false"
      centered
      :maskClosable="false"
    >
      <a-spin :spinning="pageLoadFlagbq">
        <div class="modal_close" @click="clickTIPs">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>新建用户标签
      </span>
        </div>
        <div class="cancelContent">
          <div
            v-for="(item,index) in therrr"
            :key="index"
            @click="changeTipsBtn(item,'1')"
            :class="item.checked?'clickBtnActive':'clickBtn'"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="cancelInfo">
          <span class="cancleZi">自定义：</span>
          <a-input
            placeholder="请输入用户标签"
            :value="tipsArea"
            v-model="tipsArea"
          />
        </div>
        <div class="cancelInfo">
          <span class="cancleZi">标签说明：</span>
          <a-input
            placeholder="请输入用户标签说明"
            :value="userTip"
            v-model="userTip"
          />
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import { findList, findListLabel, saveInfo, saveInfoLabel } from "@/views/userOperations/IntendedUserEntry/api";
import { SalesShopInfoList } from "@/views/subOrder/api";
import { custAccountDict, getUser } from "./../components/saleChnge/api";

export default {
  components: {},
  data() {
    return {
      pageLoadFlagLy: false,
      pageLoadFlagbq: false,
      pageLoadFlag: false,// 整个页面的loading
      visibleLY: false,// 新建用户来源弹窗
      visibleTips: false,// 新建用户标签弹窗
      userSorceList: [],
      // sourceId: "",// 选中的用户来源
      userTipsList: [],
      TipsIds: [],// 选中的标签
      materialGroup: [],
      priceGroup: [],
      productList: [],
      userself: "",

      tipsArea: "",
      userTip: "",
      formInfo: {
        phoneShow: "",// 显示的手机号
        phone: "",// 手机号
        userName: "",// 姓名
        gender: 0,// 性别
        wechat: 0,
        sourceId: "",
        followPeople: ""
      },
      sourceIdTc:'',// 弹窗里面的值
      therrr:[],
      TipsIdTC:[]

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: ""
    }

  },

  watch: {
    userId: function(val) {
      this.userId = val;
    },
    visible: function(val) {
      if (val) {
        this.getUserInfo();
      }
    }

  },
  mounted() {
    this.getUserInfo();
    this.getPerson();

  },
  methods: {
    clickTIPs() {
      this.TipsIdTC = this.TipsIds
      if(this.userTipsList.length>0 &&this.TipsIds.length > 0 ) {
        this.userTipsList.forEach((item) => {
          item.checked = false;
          this.TipsIds.forEach((element) => {
            if (item.id == element) {
              item.checked = true;
            }
          });
        })
      } else if(this.userTipsList.length>0 && this.TipsIds.length <= 0 ){
        this.userTipsList.forEach((item) => {
          item.checked = false;
        })
      }
      this.visibleTips = false;
    },
    clickLY() {
      this.sourceIdTc = this.formInfo.sourceId
      this.visibleLY = false;
    },
    // 顶部用户信息
    getUserInfo() {
      let data = {
        userId: this.userId
      };
      getUser(data).then(res => {
        if (res.data.code == 0) {
          this.formInfo = res.data.data;
          if(this.formInfo.sourceName == '活动报名') {
            this.formInfo.wechat = 1
          }
          // this.formInfo.phoneShow = res.data.data.phone.replace(/(\d{3})(\d{4})(\d{4})/,"$1****$3")
          if(res.data.data.followPeople) {
            this.formInfo.followPeople = res.data.data.followPeople.toString();
          }

          this.TipsIds = this.formInfo.labelList;
          this.getuserSorceList();
          this.getuserTipList();
        }

      });
    },
    // 获取跟进人
    getPerson() {
      custAccountDict({}).then(res => {
        this.productList = res.data.list;
        this.productList.forEach(item => {
          item.code = (item.code.toString());
        });
      });
    },
    // 标签确定
    TipsOk() {
      if (!this.tipsArea) {
        this.TipsIds = this.TipsIdTC
        if(this.userTipsList.length>0 &&this.TipsIds.length > 0 ) {
          this.userTipsList.forEach((item) => {
            item.checked = false;
            this.TipsIds.forEach((element) => {
              if (item.id == element) {
                item.checked = true;
              }
            });
          })
        }
        this.$forceUpdate()
        this.visibleTips = false;
        return;
      }
      if (this.TipsIds.length < 8) {
        let data = {
          custInfoId: this.$store.state.user.userInfo.customer.id,
          type: 2,
          label: this.tipsArea,
          remark: this.userTip
        };
        this.pageLoadFlagbq = true;
        saveInfoLabel(data).then(res => {
          if (res.data.code == 0) {
            this.userTipsList.push(
              {
                id: res.data.data,
                name: this.tipsArea,
                checked: true
              }
            );
            this.TipsIds.push(res.data.data);
            this.TipsIdTC.push(res.data.data);
            this.tipsArea = "";
            this.remark = "";
            this.userTip = "";
            this.getuserSorceList();
            this.getuserTipList();
            this.pageLoadFlagbq = false;
            this.visibleTips = false;
          } else {
            this.$message.warning(res.data.msg);
            this.tipsArea = "";
            this.remark = "";
            this.pageLoadFlagbq = false;
            this.visibleTips = false;
          }

        });
      } else {
        this.$message.warning("已选中8个标签，不能再新增");
        return;
      }
    },
    // 打开用户来源弹窗 并给弹窗内id 赋值
    openVisebleLy(){
      this.sourceIdTc = this.formInfo.sourceId
      this.visibleLY = true
    },
    // 用户来源标签确定
    visibleLYOk() {
      if (!this.userself) {
        this.formInfo.sourceId = this.sourceIdTc
        this.visibleLY = false;
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: this.userself,
        remark: ""
      };
      this.pageLoadFlagLy = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
            {
              id: res.data.data,
              name: this.userself
            }
          );
          this.getuserSorceList();
          this.getuserTipList();
          this.pageLoadFlagLy = false;
          this.visibleLY = false;
          this.userself = ''
          this.formInfo.sourceId = "";
          this.formInfo.sourceId = this.userSorceList[this.userSorceList.length - 1].id;
          this.sourceIdTc = this.userSorceList[this.userSorceList.length - 1].id;

          this.$forceUpdate();
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlagLy = false;
          this.visibleLY = false;
          this.userself = ''
        }
      });


    },
    includes(arr1, arr2) {
      return arr2.every(val => arr1.includes(val.id));
    },
    // 获取用户标签
    getuserTipList() {
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        this.userTipsList = res.data.data;
        this.therrr = res.data.data;
        if (res.data.data.length > 0 && this.TipsIds.length > 0) {
          this.userTipsList.forEach((item) => {
            item.checked = false;
            this.TipsIds.forEach((element) => {
              if (item.id == element) {
                item.checked = true;
              }
            });
          })
          this.$forceUpdate()
        } else if (this.userTipsList > 0) {
          this.userTipsList.forEach((item) => {
            item.checked = false;
          });
        }

      });
    },
    // 获取用户来源
    getuserSorceList() {
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        this.userSorceList = res.data.data;
      });
    },

    // 修改性别
    changSex(e) {
      this.sex = e.target.value;
    },
    // 修改是否添加微信
    changWX(e) {
      this.addWX = e.target.value;
    },
    onAddressChange(addressValArr) {
      if(addressValArr&&addressValArr.length == 2) {
        this.quCode = '';
      }
      // 长度如果等于3 就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.xianCode = '';
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.shengCode = item;
          }
          if (index == 1) {
            this.shiCode = item;
          }
          if (index == 2) {
            this.quCode = item;
          }
          if (index == 3) {
            this.xianCode = item;
          }

        });
      } else {
        this.shengCode = ''
        this.shiCode = ''
        this.quCode = ''
        this.xianCode = ''
      }

      // ['340000000000', '340600000000', '340603000000', '340603400000', __ob__: Observer]
      // ..
    },
    // 点击
    changeSorceBtn(item,type) {
      if(type == 0) {
        if(this.formInfo.sourceId == item.id) {
          this.formInfo.sourceId = null
          return
        }
        this.formInfo.sourceId = item.id;
        this.$forceUpdate();
      } else {
        if(this.sourceIdTc == item.id) {
          this.sourceIdTc = null
          return
        }
        this.sourceIdTc = item.id
        this.$forceUpdate();
      }

    },
    // 打开用户标签弹窗
    openvisibleTips(){
      this.TipsIdTC = this.TipsIds
      this.$forceUpdate()
      if(this.therrr.length>0 &&this.TipsIdTC.length > 0 ) {
        this.therrr.forEach((item) => {
          item.checked = false;
          this.TipsIdTC.forEach((element) => {
            if (item.id == element) {
              item.checked = true;
            }
          });
        })
      }
      this.visibleTips = true
      this.$forceUpdate()
    },

    changeTipsBtn(item,type) {
      if(type == 0) {
        if (!item.checked) {
          if (this.TipsIds.length >= 8) {
            this.$message.warning("用户标签选择不能超过8个");
            return;
          }

        }
        item.checked = !item.checked;
        this.$forceUpdate();
        this.TipsIds = [];
        this.userTipsList.forEach(items => {
          if (items.checked == true) {
            this.TipsIds.push(items.id);
          }
        });
        this.$forceUpdate();
      } else {
        if (!item.checked) {
          if (this.TipsIdTC.length >= 8) {
            this.$message.warning("用户标签选择不能超过8个");
            return;
          }
        }
        item.checked = !item.checked;
        this.$forceUpdate();
        this.TipsIdTC = [];
        this.therrr.forEach(items => {
          if (items.checked == true) {
            this.TipsIdTC.push(items.id);
          }
        });
        this.$forceUpdate();
      }
    },

// 修改意向品类
    handleChangespart() {
    },
    handleOk() {
      if (!this.formInfo.userName) {
        this.$message.warning("请填写用户名");
        return;
      }
      if (!this.formInfo.phone) {
        this.$message.warning("请填写手机号");
        return;
      }
      if (this.formInfo.phone.trim().length !== 11) {
        this.$message.warning("手机号不正确，请重新填写");
        return;
      }
      if (!this.formInfo.gender) {
        this.$message.warning("请选择性别");
        return;
      }
      let data = {
        userName: this.formInfo.userName,// 意向用户名
        phone: this.formInfo.phone,
        wechat: this.formInfo.wechat,// 是否微信
        gender: this.formInfo.gender,
        sourceId: this.formInfo.sourceId?this.formInfo.sourceId:'',
        labelList: this.TipsIds.length>0?Array.from(new Set(this.TipsIds)):[],
        followPeople: this.formInfo.followPeople,
        remark:this.formInfo.remark,
      };
      this.$emit("update:visible", false);
      this.$emit("cancelReasonHandOk", data);
      this.getUserInfo();


    },
    cancle() {
      this.getUserInfo();
      this.$emit("update:visible", false);

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }

  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      padding: 30px 0px 0 0px;

      .cancelContent {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;


        .selectBox {
          //margin-top: 16px;
          display: flex;
          flex-direction: column;

          .everyBox {
            display: flex;
            align-items: center;
            margin-left: 78px;
            margin-top: 24px;

            .lable {
              width: 98px;
              margin-right: 8px;
              text-align: right;
              font-size: 14px;
              color: #262626;

              span {
                font-size: 14px;
                font-weight: 400;
                color: #D9161C;
              }
            }

            /deep/ .ant-select-selection {
              width: 416px;
              height: 32px;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
            }

            .aInput {
              width: 416px !important;
              height: 32px !important;
              background: #FFFFFF;
              border-radius: 4px;
              border: 1px solid #EEEEEE;
              color: #262626;
            }

            .userInput {
              width: 200px;
              margin-right: 16px;
            }

            .radioGroup {
              margin-left: 8px;
            }

            .userSorce {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              //align-items: center;
              max-width: 530px;

              .smallSorce {
                padding: 0 14px 0 14px;
                color: #00AAA6;
                height: 32px;
                border-radius: 16px;
                border: 1px solid #EEEEEE;
                text-align: center;
                line-height: 32px;
                margin-right: 8px;
                margin-bottom: 12px;
                cursor: pointer;
              }

              .smallSorceActice {
                cursor: pointer;
                height: 32px;
                background: #00AAA6;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                padding: 0 14px 0 14px;
                color: #fff;
                margin-right: 8px;
                margin-bottom: 12px;
                //border: none;
              }
            }

            .circleBox {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 1px solid #EEEEEE;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 12px;
                height: 12px;
              }
            }

          }
          .everyBox:first-child{
            margin-top: 30px;
          }

          .proBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start;

            .prooneBox {
              display: flex;

              .prouctBox {
                display: flex;
                flex-direction: column;

                .proItem {
                  display: flex;
                  margin-bottom: 16px;
                  align-items: center;

                  /deep/ .ant-select-selection {
                    width: 180px;
                    height: 32px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    border: 1px solid #EEEEEE;
                  }

                }
              }
            }

            .addNEWbTN {
              cursor: pointer;
              width: 180px;
              height: 32px;
              border-radius: 3px;
              border: 1px dashed #EEEEEE;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #1890FF;
              margin-left: 332px;

              img {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }

          }

          .biaoqian {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .biaoqianBox {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start
            }

            .tips {
              font-size: 12px;
              font-weight: 400;
              color: #AAAAAA;
              margin-left: 109px;
              margin-top: -6px;
            }

          }
          .laiyuan {
            margin-top: 12px;
          }

          .adress {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            div {
              display: flex;
            }

            .userDsInputress {
              margin-top: 16px;
              margin-left: 106px;
              width: 500px;
            }
          }
        }


        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #AAAAAA;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }



      .modal_close {

        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }

}

/deep/ .cancelNameYHLY {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        .btnGroup {
          display: flex;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
        }

        display: flex;
        justify-content: center;
        width: 470px;

        .clickBtn {
          cursor: pointer;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 36px;
        margin-top: 12px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 56px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px
        }

        .ant-input {
          width: 300px;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}

/deep/ .cancelNameTips {
  font-family: PingFangSC-Medium, PingFang SC;

  .ant-modal-content {
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .cancelContent {
        display: flex;
        flex-wrap: wrap;
        width: 418px;

        .clickBtn {
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 12px;
          cursor: pointer;
        }

        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 12px;
        }
      }

      .cancelInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        //margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        margin-top: 12px;

        .cancleZi {
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }

        .ant-input {
          width: 300px;
          height: 32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: -24px;
        right: -114px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


